/**
 * Theme for WSJ US site
 */
import type { Theme } from '@emotion/react';
import { buildTheme, globals } from 'common-ui';
import merge from 'lodash/merge';
import { icons } from 'common-font-icons';
import type { CommonThemeVars, ThemeCreateFunction } from 'tsconfig/types';
import { buildThemeVarsUsLW, buildThemeMixinsUsLW, buildThemeWidgetStylesUsLW } from 'dw-us-law/theme';
import { escrowbannerFont, escrowFont } from '../common/nextFont';

export const buildThemeVarsUSWSJ = (vars: Pick<Theme, CommonThemeVars>) => {
  //primary
  const wsjRed = '#E92440'; //color1
  const wsjBlack = '#2B2A2F'; //color2
  const wsjCream = '#F6F5F2'; //color3
  const wsjGrey = '#EDEDED'; //color4
  const wsjCharcoal = '#494949'; //color5
  //secondary
  const wsjPink = '#EEE1F8'; //color5
  const wsjLightGreen = '#C9E7E1'; //color6
  const wsjBlue = '#93BACD'; //color7
  const wsjOrange = '#FAE1C8'; //color8
  const wsjDuckEgg = '#3E837C'; //color9
  const wsjSand = '#F7AB89'; //color10
  const wsjDarkBlue = '#0274A9'; //color11
  const wsjTeal = '#2C6F8A'; //color12
  const wsjLilac = '#A0ABCC'; //color13
  const wsjWhiteSmoke = '#EDEDED'; //color14
  const wsjBlue600 = '#0055A0';
  const wsjSvgCheckboxBg = '%23E92440';

  //CTA colors
  const wsjCTABlack = wsjBlack;
  const wsjCTARed = wsjRed;
  const wsjCTARolloverRed = '#b9132a';
  const wsjCTACharcoal = wsjCharcoal;
  const wsjCTAGreen = '#117B53';

  const wsWhiteWine = '#EDEDED';
  const wsUnlimitedPopupbackground = '#EDEDED';
  const wsUnlimitedButtonColor = '#021C34';
  const wsUnltPopupColor = '#222222';

  const arial = 'Arial, Helvetica, sans-serif';
  const escrow = escrowFont.style.fontFamily;
  const escrowbanner = escrowbannerFont.style.fontFamily;

  return merge({}, buildThemeVarsUsLW(vars), {
    meta: {
      name: 'WSJ US',
    },
    icons,
    assets: {
      headerLogoImgUrl: `${globals.assetsPath}/next/images/brand-logo.svg`,
      headerLogoMobileImgUrl: `${globals.assetsPath}/next/images/brand-logo-mobile.svg`,
      footerLogoImgUrl: `${globals.assetsPath}/next/images/brand-logo-dark.svg`,
    },
    colors: {
      primary1: wsjRed,
      primary2: wsjBlack,
      primary3: wsjWhiteSmoke,
      primary4: wsjGrey,
      primary5: wsjCharcoal,
      secondary1: wsjPink,
      secondary2: wsjLightGreen,
      secondary3: wsjBlue,
      secondary4: wsjOrange,
      secondary5: wsjDuckEgg,
      secondary6: wsjSand,
      secondary7: wsjDarkBlue,
      secondary8: wsjTeal,
      secondary9: wsjLilac,
      secondary10: wsjCTAGreen,
      unlimitedButtonColor: wsUnlimitedButtonColor,
      unltPopupBackground: wsUnlimitedPopupbackground,
      unltPopupColor: wsUnltPopupColor,
      untlPopupButtonColor: vars.colors.white,
      untlPopupButtonBgColor: wsjCTARolloverRed,
      unltPopupcloseBtn: wsUnltPopupColor,
      cardBackground: wsjDarkBlue,

      //specific colour definitions
      btnsPrimaryBg: wsjCTARed,
      btnsPrimaryColor: vars.colors.white,
      btnsPrimaryHoverBg: wsjCTARolloverRed,
      btnsPrimaryHoverColor: vars.colors.white,
      btnsSecondaryBg: vars.colors.white,
      btnsSecondaryColor: vars.colors.black,
      btnsSecondaryHoverBg: wsjCTARolloverRed,
      btnsSecondaryHoverColor: vars.colors.white,
      btnsTertiaryBg: vars.colors.black,
      btnsTertiaryColor: vars.colors.white,
      btnsTertiaryBorder: vars.colors.white,
      btnsTertiaryHoverBg: wsjCTABlack,
      btnsTertiaryHoverColor: vars.colors.white,
      svgCheckboxBg: wsjSvgCheckboxBg,

      radioBtnsPrimaryBg: wsjCTARolloverRed,
      textPrimaryBg: wsjRed,
      textUnlimitedBg: wsjDarkBlue,

      linkPrimaryColor: vars.colors.black,
      linkSecondaryColor: wsjCTABlack,
      linkTertiaryColor: wsjCTACharcoal,

      headerCartBtn: wsjCTABlack,
      headerCartBtnSmall: wsjCTABlack,
      headerSignInBtnSmall: wsjCTACharcoal,

      blue600: wsjBlue600,
      bgColors: {
        WSJRed: wsjRed,
        WSJCharcoal: wsjCharcoal,
        WSJBlack: wsjBlack,
        WSJCream: wsjCream,
        WSJGrey: wsjGrey,
        WSJPink: wsjPink,
        WSJLightGreen: wsjLightGreen,
        WSJBlue: wsjBlue,
        WSJOrange: wsjOrange,
        WSJDuckEgg: wsjDuckEgg,
        WSJSand: wsjSand,
        WSJDarkBlue: wsjDarkBlue,
        WSJTeal: wsjTeal,
        WSJLilac: wsjLilac,
        whiteWine: wsWhiteWine,
      },
    },
    fonts: {
      familyPrimary: escrow,
      familySecondary: arial,
      familyTertiary: escrow,
      familyHeader: escrowbanner,
      headerMainNavTitle: arial,
      headerMainNavSubTitle: arial,
      sizeH6: '18px',
      sizeH7: '20px',
      sizeH8: '22px',
      sizeH9: '26px',
      sizeH10: '30px',
      weightSmall: '400',
      weightMediumPlus: '600',
      weightBold: '700',
    },
  });
};
export const buildThemeMixinsUSWSJ = (vars: Omit<Theme, 'mixins' | 'widgets'>) => {
  const buildThemeWidget = buildThemeMixinsUsLW(vars);

  return merge({}, buildThemeWidget, {
    hxl: `
      font-family: ${vars.fonts.familyHeader};
      font-weight: ${vars.fonts.weightBold};
      font-size: ${vars.fonts.fontSize44};
      line-height: ${vars.fonts.fontLineHeight100};
      letter-spacing: ${vars.fonts.fontLetterSpacing5};
      text-transform: ${vars.fonts.caseUpper};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize60};
      }
    `,
    h1: `
      font-family: ${vars.fonts.familyHeader};
      font-weight: ${vars.fonts.weightBold};
      font-size: ${vars.fonts.fontSize34};
      line-height: ${vars.fonts.fontLineHeight105};
      letter-spacing: ${vars.fonts.fontLetterSpacing5};
      text-transform: ${vars.fonts.caseUpper};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize48};
      }
    `,
    h2: `
      font-family: ${vars.fonts.familyHeader};
      font-weight: ${vars.fonts.weightBold};
      font-size: ${vars.fonts.fontSize30};
      line-height: ${vars.fonts.fontLineHeight105};
      letter-spacing: ${vars.fonts.fontLetterSpacing5};
      text-transform: ${vars.fonts.caseUpper};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize34};
      }
    `,
    h3: `
      font-family: ${vars.fonts.familyHeader};
      font-weight: ${vars.fonts.weightBold};
      font-size: ${vars.fonts.fontSize26};
      line-height: ${vars.fonts.fontLineHeight110};
      letter-spacing: ${vars.fonts.fontLetterSpacing5};
      text-transform: ${vars.fonts.caseUpper};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize30};
      }
    `,
    h4: `
      font-family: ${vars.fonts.familyPrimary};
      font-weight: ${vars.fonts.weightSemiBold};
      font-size: ${vars.fonts.fontSize26};
      line-height: ${vars.fonts.fontLineHeight120};
      letter-spacing: ${vars.fonts.fontLetterSpacing5};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize30};
      }
    `,
    h5: `
      font-family: ${vars.fonts.familyPrimary};
      font-weight: ${vars.fonts.weightSemiBold};
      font-size: ${vars.fonts.fontSize24};
      line-height: ${vars.fonts.fontLineHeight120};
      letter-spacing: ${vars.fonts.fontLetterSpacing5};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize26};
      }
    `,
    h6: `
      font-family: ${vars.fonts.familyPrimary};
      font-weight: ${vars.fonts.weightSemiBold};
      font-size: ${vars.fonts.fontSize20};
      line-height: ${vars.fonts.fontLineHeight120};
      letter-spacing: ${vars.fonts.fontLetterSpacing5};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize22};
      }
    `,
    h7: `
      font-family: ${vars.fonts.familyPrimary};
      font-weight: ${vars.fonts.weightBold};
      font-size: ${vars.fonts.fontSize16};
      line-height: ${vars.fonts.fontLineHeight140};
      letter-spacing: ${vars.fonts.fontLetterSpacing5};
      ${vars.breakpoints.sm} {
        font-weight: ${vars.fonts.weightSemiBold};
        font-size: ${vars.fonts.fontSize18};
      }
    `,
    h8: `
      font-family: ${vars.fonts.familySecondary};
      font-weight: ${vars.fonts.weightBold};
      font-size: ${vars.fonts.fontSize16};
      line-height: ${vars.fonts.fontLineHeight140};
      letter-spacing: ${vars.fonts.fontLetterSpacing6};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize18};
      }
    `,
    eyebrowRegular: `
      font-family: ${vars.fonts.familySecondary};
      font-size: ${vars.fonts.fontSize12};
      font-weight: ${vars.fonts.weightBold};
      line-height: ${vars.fonts.fontLineHeight140};
      letter-spacing: ${vars.fonts.fontLetterSpacing7};
      text-transform: ${vars.fonts.caseUpper};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize14};
      }
    `,
    eyebrowSmall: `
      font-family: ${vars.fonts.familySecondary};
      font-size: ${vars.fonts.fontSize10};
      font-weight: ${vars.fonts.weightBold};
      line-height: ${vars.fonts.fontLineHeight140};
      letter-spacing: ${vars.fonts.fontLetterSpacing7};
      text-transform: ${vars.fonts.caseUpper};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize12};
      }
    `,
    utility: `
      font-family: ${vars.fonts.familySecondary};
      font-size: ${vars.fonts.fontSize12};
      font-weight: ${vars.fonts.weightNormal};
      line-height: ${vars.fonts.fontLineHeight130};
      letter-spacing: ${vars.fonts.fontLetterSpacing8};
      text-transform: ${vars.fonts.caseUpper};
    `,
    quote: `
      font-family: ${vars.fonts.familyPrimary};
      font-size: ${vars.fonts.fontSize20};
      font-style: ${vars.fonts.styleItalic};
      line-height: ${vars.fonts.fontLineHeight120};
      letter-spacing: ${vars.fonts.fontLetterSpacing4};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize26};
      }
    `,
    body1: `
      font-family: ${vars.fonts.familySecondary};
      font-size: ${vars.fonts.fontSize16};
      font-weight: ${vars.fonts.weightNormal};
      line-height: ${vars.fonts.fontLineHeight140};
      letter-spacing: ${vars.fonts.fontLetterSpacing5};
    `,
    body2: `
      font-family: ${vars.fonts.familySecondary};
      font-size: ${vars.fonts.fontSize14};
      font-weight: ${vars.fonts.weightNormal};
      line-height: ${vars.fonts.fontLineHeight130};
      letter-spacing: ${vars.fonts.fontLetterSpacing5};
    `,
    bodySmall: `
      font-family: ${vars.fonts.familySecondary};
      font-size: ${vars.fonts.fontSize12};
      font-weight: ${vars.fonts.weightNormal};
      line-height: ${vars.fonts.fontLineHeight130};
      letter-spacing: ${vars.fonts.fontLetterSpacing5};
    `,
    caption: `
      font-family: ${vars.fonts.familySecondary};
      font-size: ${vars.fonts.fontSize12};
      font-weight: ${vars.fonts.weightNormal};
      line-height: ${vars.fonts.fontLineHeight120};
      letter-spacing: ${vars.fonts.fontLetterSpacing5};
    `,
    btnPrimary: `
        ${buildThemeWidget.btnPrimary}
        &.desktop-sticky-content:hover, :hover{
          color: ${vars.colors.white} !important;
        }
    `,
  });
};
export const buildThemeWidgetStylesUSWSJ = (vars: Omit<Theme, 'widgets'>) => {
  const buildThemeWidget = buildThemeWidgetStylesUsLW(vars);

  return merge({}, buildThemeWidget, {
    GlobalHeader: {
      mainNavBarWrapper: `
        ${buildThemeWidget.GlobalHeader.mainNavBarWrapper}
        .megaNavTab {
          color:${vars.colors.unltPopupColor};
          font-family: ${vars.fonts.familyTertiary};
          letter-spacing: ${vars.fonts.fontLetterSpacing5};
          font-weight: ${vars.fonts.weightSemiBold};
        }
      `,
      searchNavBar: `
        ${buildThemeWidget.GlobalHeader.searchNavBar}
        .searchbar-cancel-container .searchbar-cancel-link:hover {
          color: ${vars.colors.btnsSecondaryColor} !important;
        }
      `,
    },
    Footer: {
      default: `
      ${buildThemeWidget.Footer.default}
      font-family: ${vars.fonts.familyTertiary};
      p {
        font-family: ${vars.fonts.familyTertiary};
      }
      @media print {
        display: none;
      }
      .footer-bottom-section .wrapper {
        .copyrights * {
          font-family: ${vars.fonts.familyTertiary};
          color: ${vars.colors.fontCopyrights}
        }
        ${vars.breakpoints.lg} {
          .bedrinkaware-group {
            justify-content: flex-start;
          }
        }
      }
      .footer-nav-links {
        background: ${vars.colors.primary4};
        box-shadow: 0 0 0 100vmax ${vars.colors.primary4};
      }
      .navItem-navigation {
        .tab-panel {
          ul {
            li {
              a {
                font-family: ${vars.fonts.familySecondary};
              }
            }
          }
        }
      }
      .social-connections {
        .wrapper {
          .social-connection-heading p {
            letter-spacing: 2px;
          }
        }
      }
      `,
      navItems: `
        ${buildThemeWidget.Footer.navItems}
        > div:first-of-type p{
          text-transform: uppercase;
          font-weight: bold;
          font-family: ${vars.fonts.familySecondary};
        }
  `,
    },
    Search: {
      hitWrapper: ` 
      ${buildThemeWidget.Search.hitWrapper}
      &.grid-view { 
        .ais-Hits-list {
          .ais-Hits-item {
            .hit-content { 
              .web-description {
                color: ${vars.colors.grey50}
              }
              .presell-section {
                font-weight: ${vars.fonts.weightMediumPlus};
              }
            }
          }
        }
      } 
      `,
      pagination: `
      ${buildThemeWidget.Search.pagination}
      .results {
        .options {
          min-width: 194px;
        }
      }
      `,
    },
    DrawerMenu: {
      mobileNavMenuContainer: `
      ${buildThemeWidget.DrawerMenu.mobileNavMenuContainer}
        a.navMenuBackLink{
          background:${vars.colors.primary5} !important;
          span {
              color: ${vars.colors.white} !important;
          }
          i{
              color: ${vars.colors.white};
          }
        }
        .mantine-Drawer-body & .level1Link span:hover {
          color: ${vars.colors.primary5};
        }
        .mantine-Drawer-body & a.level1Link:hover {
          color: ${vars.colors.primary5};
        }
      `,
    },
    StateSelector: {
      default: `
        ${buildThemeWidget.StateSelector.default}
        .verify-state-btn:focus:hover {
          color: ${vars.colors.btnsSecondaryColor} !important;
        }
      `,
    },
    CmsArticle: {
      wrapper: `
      ${buildThemeWidget.CmsArticle.wrapper}
      .secondary {
        color: ${vars.colors.primary1} !important;
      }`,
    },
    Cart: {
      slider: `
      h2 {
        &.mantine-Drawer-title {
          text-transform: capitalize !important;
          font-weight: ${vars.fonts.weightMediumPlus} !important;
        }
      }
      ${buildThemeWidget.Cart.slider}
      `,
      MiniCart: `
        ${buildThemeWidget.Cart.MiniCart}

        .price-section {
          gap:8px;
          font-weight: ${vars.fonts.weightMediumPlus};
        }

        .price-section .strike {
          color:${vars.colors.grey60};
          text-decoration-line: line-through;
        }

        .member-savings {
          color: ${vars.colors.secondary7};
          font-family: ${vars.fonts.roboto};
        }

        .button-container {
          button {
            font-weight: ${vars.fonts.weightBold};
            cursor:pointer;
          }

          span {
            font-family: ${vars.fonts.familySecondary};
            font-size: 14px;
            font-weight: ${vars.fonts.weightBold};
            line-height: 16px;
            letter-spacing: 0.02em;
          }
        }

        .continue-shopping {
          font-weight: ${vars.fonts.weightBold};
          
        }

        .continue-shopping .continue-shopping-text {
          font-size: 12px;
        }
        .mantine-Modal-content {
          .mini-cart-title {
            font-weight: ${vars.fonts.weightMediumPlus};
          }
        } 

        .price-section .strike+.member-price {
          color:${vars.colors.secondary7};
        }
      `,
      productItemsTrending: `
        ${buildThemeWidget.Cart.productItemsTrending}

        .product-title {
          font-weight: ${vars.fonts.weightMediumPlus};
          line-height: 25.2px;
          font-size: 18px;
          ${vars.breakpoints.xs}{
            font-size: 16px;
            line-height: 22.4px;
          }
          ${vars.breakpoints.sm}{
            font-size: 18px;
            line-height: 25.2px;
          }
        }

        span.price-cart {
          font-weight: ${vars.fonts.weightMediumPlus};
          ${vars.breakpoints.xs}{
            line-height: 22.4px;
          }
          ${vars.breakpoints.sm}{
            line-height: 25.2px;
          }
      `,
    },
  });
};

const createTheme: ThemeCreateFunction = () =>
  buildTheme(buildThemeVarsUSWSJ, buildThemeMixinsUSWSJ, buildThemeWidgetStylesUSWSJ);

export default createTheme;
